import React from "react";

const Wrapper = ({ children, className, isNotPy = false }) => {
  return (
    <section
      className={`h-full w-full container m-auto px-5 ${className} ${
        isNotPy ? "" : "py-16 md:py-20"
      }`}
    >
      {children}
    </section>
  );
};

export default Wrapper;
