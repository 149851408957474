import React, { useState } from "react";
import { RouterProvider } from "react-router-dom";
import { AppProvider } from "./shared";
import { AppDataJSON, router } from "./utils";

// React swiper plugin
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

// Import Swiper styles
import "swiper/css";
import "swiper/css/bundle";

// Import react-responsive-carousel
import "react-responsive-carousel/lib/styles/carousel.min.css";

const App = () => {
  const [appData, setAppData] = useState(AppDataJSON);

  return (
    <AppProvider value={{ appData, setAppData }}>
      <RouterProvider router={router} />
    </AppProvider>
  );
};

export default App;
