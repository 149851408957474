import Lottie from "lottie-react";
import React from "react";
import DroneAnimation from "../assets/json/Pre-Loader.json";

const LoaderPage = () => {
  return (
    <div className="w-full h-full flex flex-row absolute justify-center">
      <Lottie
        className="absolute h-full w-5/12 p-0 m-0 z-30"
        loop
        animationData={DroneAnimation}
      />
      <div className="bottom-1/3 absolute animate-pulse font-bold text-4xl tracking-wider z-30">
        Loading...
      </div>
      {/* Overlay */}
      <div className="absolute top-0 left-0 right-0 bottom-0 bg-primary z-[2]" />
    </div>
  );
};

export default LoaderPage;
